import request from '@/utils/request'

// 下拉陈列活动
export function activeList(data) {
  return request({
    url: '/baseAdmin/common/display-model',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {协议异常} data
 */
// 协议异常
export function xieyiList(data) {
  return request({
    url: '/promoteAdmin/display/deal-list',
    method: 'post',
    data,
  })
}
// 陈列协议详情
export function xieyiDetail(data) {
  return request({
    url: '/promoteAdmin/display/participated-view',
    method: 'post',
    data,
  })
}

// 终止已参加的 d_cust_id
export function stopPayList(data) {
  return request({
    url: '/promoteAdmin/display/participated-stop',
    method: 'post',
    data,
  })
}

// 协议修改
export function editPayList(data) {
  return request({
    url: '/promoteAdmin/display/participated-update',
    method: 'post',
    data,
  })
}

// 协议审核
export function reviewPayList(data) {
  return request({
    url: '/promoteAdmin/display/audit-dealbnormal',
    method: 'post',
    data,
  })
}

/**
 *
 * @param {兑付异常} data
 */
// 兑付异常
export function duifuList(data) {
  return request({
    url: '/promoteAdmin/display/cashing-list',
    method: 'post',
    data,
  })
}

// 兑付异常详情
export function duifuDetail(data) {
  return request({
    url: '/promoteAdmin/display/cashing-list-view',
    method: 'post',
    data,
  })
}

// 审核兑付异常
export function duifuReview(data) {
  return request({
    url: '/promoteAdmin/display/audit-cashingbnormal',
    method: 'post',
    data,
  })
}

/**
 * 付费陈列统计
 */

// 查询列表
export function summaryList(data) {
  return request({
    url: '/promoteAdmin/display/statistics',
    method: 'post',
    data,
  })
}

// 导出报表
export function exportList(data) {
  return request({
    url: '/promoteAdmin/display/statistics-export',
    method: 'post',
    data,
  })
}
